.Footer {
  text-decoration: none;
  font-family: AvenirNext-Regular;
  font-size: 12px;
  color: #8E8E93;
  letter-spacing: 1.33px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.FooterLink {
  text-decoration: none;
  font-family: AvenirNext-Regular;
  font-size: 12px;
  color: #8E8E93;
  letter-spacing: 1.33px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.FooterCopyright {
  text-decoration: none;
  font-family: AvenirNext-Regular;
  font-size: 12px;
  color: #8E8E93;
  letter-spacing: 1.33px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}