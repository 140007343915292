.SignUp {
  background: #027AFF;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  font-family: AvenirNext-Medium;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 1.33px;
  text-align: center;
  transition: background 250ms, width 250ms, height 250ms, border-radius 250ms, letter-spacing 250ms;
}

.SignUp:hover {
  cursor: pointer;
  width: 210px;
  height: 42px;
  border-radius: 21px;
  letter-spacing: 1.65px;
  background: #006AE0;
}

.SignUpContainer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}