.HomePage {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.HomePageMain {
  text-align: center;
  height: 100vh;
  width: 100vw;
  padding: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}