.ExtensionLoginPage {
  text-align: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  max-height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C7C7CC;
}

.LogOut {
  cursor: pointer;
}

.ExtensionLoginTitle {
  font-family: Roboto-Regular;
  font-size: 24px;
  color: #8E8E93;
  letter-spacing: 6px;
  text-align: center;
  padding: 10px;
}

.ExtensionLoginPageContainer {
  width: 250px;
  height: 150px;
  background: #FFFFFF;
  border: 1px solid #E5E5EA;
  border-radius: 9px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.ExtensionLoginAuthContainer {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.ExtensionHighlight {
  color: #027AFF;
}