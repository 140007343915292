.Content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
}

.ContentExplanation {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}