.HeaderTitle {
  font-family: AvenirNext-Regular;
  font-size: 42px;
  color: #646464;
  letter-spacing: 6px;
  text-align: center;
}

.HeaderTitleEmphasis {
  color: #007AFF;
}

.HeaderSubTitle {
  font-family: BespokeSerif-MediumItalic;
  font-size: 16px;
  color: #C7C7CC;
  letter-spacing: 3.43px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: inline;
  transition: color 250ms;
}

.HeaderSubTitle:hover {
  color: #A7A7AC;
}

.HeaderSubTitle:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #C7C7CC;
  border-radius: 1px;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.HeaderSubTitle:hover:before {
  visibility: visible;
  background-color: #A7A7AC;
  transform: scaleX(1);
}