.TermsAndConditions {
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}

.TermsAndConditionsFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.TermsAndConditionsSpacer {
  height: 20px;
}

.TermsAndConditionsTitle {
  font-family: ClearSans-Bold;
  font-size: 36px;
  color: #C4C4C4;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.TermsAndConditionsTitleList {
  font-family: ClearSans;
  font-size: 12px;
  color: #B5B5B5;
  letter-spacing: 1px;
  text-align: left;
  line-height: 21px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.TermsAndConditionsTitleList li {
  padding-bottom: 20px;
}

.TermsAndConditionsTitle:hover {
  opacity: 0.8;
}

.TermsAndConditionsHighlight {
  color: #F0BE00;
}

.TermsAndConditionsItem {
  font-family: ClearSans-Medium;
  font-size: 60px;
  color: #E8E8E8;
  letter-spacing: 5px;
  text-align: center;
}

.TermsAndConditionsSubTitle {
  font-family: ClearSans-Medium;
  font-size: 24px;
  color: #B5B5B5;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.TermsAndConditionsSuperSubTitle {
  font-family: ClearSans-Medium;
  font-size: 18px;
  color: #B5B5B5;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.TermsAndConditionsSubParagraph {
  font-family: ClearSans;
  font-size: 12px;
  color: #B5B5B5;
  letter-spacing: 1px;
  text-align: left;
  line-height: 21px;
  text-indent: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
